<template>
  <section class="section">
    <div class="container">
      <h1 class="title">Haan</h1>
      <section>
        <b-field label="ใครกินบ้าง">
          <b-taginput
            v-model="people"
            ellipsis
            icon="account-box"
            placeholder="Add a tag">
          </b-taginput>
        </b-field>
      </section>
      <section v-if="people.length > 0">
        <br>
        <h1 class="subtitle">
          เมนู
          <b-button type="is-dark" size="is-small" icon-left="plus-circle" rounded @click="addMenu()">เพิ่ม</b-button>
        </h1>
        <b-table :data="menu">
          <b-table-column field="name" label="เมนู" v-slot="props">
            <div class="field">
              <b-input 
                v-model="props.row.name"
                :ref="`food-name-${props.index}`">
              </b-input>
            </div>
          </b-table-column>
          <b-table-column field="price" label="ราคา" v-slot="props">
            <div class="field">
              <b-input 
                v-model="props.row.price"
                placeholder="0"
                :ref="`food-price-${props.index}`">
              </b-input>
            </div>
          </b-table-column>
          <b-table-column field="who" label="ใครกินบ้าง" v-slot="props">
            <div class="field">
              <b-switch v-show="props.row.everyone" 
                        v-model="props.row.everyone"
                        @input="shiftFocus(props.index)">
                ทุกคน
              </b-switch>
              <b-taginput 
                v-show="!props.row.everyone"
                v-model="props.row.people"
                :ref="`people-tag-${props.index}`"
                autocomplete
                :allow-new="false"
                :open-on-focus="true"
                @typing="getFilteredTags"
                :data="filteredPeople"
                >
              </b-taginput>
            </div>
          </b-table-column>
          <b-table-column field="delete" label="ลบ" v-slot="props">
            <b-button type="is-danger" outlined @click="deleteMenu(props.index)" icon-left="delete"></b-button>
          </b-table-column>
        </b-table>
      </section>
      <section v-if="displayResult.length > 0">
        <br>
        <h1 class="subtitle">บิล</h1>
        <b-table :data="displayResult">
          <b-table-column field="name" label="ชื่อ" v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="price" label="จ่าย" numeric v-slot="props">
            {{ props.row.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
          </b-table-column>
        </b-table>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Calculator',
  data() {
    return {
      people: [],
      filteredPeople: [],
      menu: [{
        name: "",
        price: null,
        people: [],
        everyone: true
      }]
    }
  },
  methods: {
    deleteMenu(row) {
      this.menu.splice(row, 1);
    },
    addMenu() {
      this.menu.push({
        name: "",
        price: null,
        everyone: true,
        people: []
      })
      this.$nextTick(() => {
        this.$refs[`food-name-${this.menu.length - 1}`].focus()
      })
    },
    sharedPrice(food) {
      if (food.everyone) return Number(food.price) / this.people.length
      if (food.people.length == 0) return 0
      return Number(food.price) / food.people.length
    },
    getFilteredTags(text) {
      this.filteredPeople = this.people.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },
    shiftFocus(index) {
      this.$nextTick(() => {
        this.filteredPeople = this.people
        this.$refs[`people-tag-${index}`].focus()
      })
    }
  },
  computed: {
    displayResult () {
      let result = []
      this.people.forEach((person) => {
        result.push({
          name: person,
          price: 0
        })
      })
      result.forEach((res) => {
        this.menu.forEach((food) => {
          if (food.everyone || food.people.includes(res.name)) {
            res.price += this.sharedPrice(food)
          }
        })
      })
      return result
    }
  }
}
</script>